import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button, Tag, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import api, { GetFoodCardListParameters, GetFoodCardRes, GetCommunityListParameters, GetCommunityRes } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import JJ_TableItemAction from '../../components/JJ_TableItemAction';
import JJ_TableItemMoment from '../../components/JJ_TableItemMoment';
import FoodCardEditAndAdd from './FoodCardEditAndAdd';
import { PlusOutlined, EditOutlined, DownloadOutlined } from '@ant-design/icons';
import { connect, ConnectedProps } from 'react-redux';
import JJ_TableItemCopyableId from '../../components/JJ_TableItemCopyableId';
import config from '../../config';
import FoodCardDownload from './FoodCardDownload';
import FoodCardDetailsModal from './FoodCardDetailsModal';
import moment from 'moment';
import { ActionUserRes } from '../../actions/ActionUser';

const { confirm } = Modal;

type EditAndAddModalType = { type: 'edit', id: string } | { type: 'edits', ids: string[] } | { type: 'addXlsx' } | { type: 'addFamily' } | { type: 'addFamilyMember' }

interface PageState {
  foodCardIsEnable?: boolean
  isSpinLoading: boolean
  editAndAddModal: {
    show: boolean
    key: string
  } & EditAndAddModalType
  selectedRowKeys: string[]
  foodCardDownloadModal: {
    show: boolean
    key?: any
  }
  foodCardDetailsModal: {
    show?: boolean
    key?: any
    foodCardCode: string
  }
  communities: GetCommunityRes[]
}
interface Props {
}


interface Page {
  tableRef?: JJ_Table<GetFoodCardRes> | null
}


type PageProps = Props & RouteChildrenProps & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes

}

const mapState = (state: RootState) => ({
  user: state.user
})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class FoodCardList extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);
    this.state = {
      foodCardDownloadModal: {
        show: false,
      },
      selectedRowKeys: [],
      isSpinLoading: true,
      editAndAddModal: {
        type: 'addXlsx',
        show: false,
        key: new Date().toString()
      },
      foodCardDetailsModal: {
        foodCardCode: '',
      },
      communities: [],
    }
  }
  tableRef?: JJ_Table<GetFoodCardRes> | null
  componentDidMount() {
    this._initData()
  }



  _getCommunityList = async (params: GetCommunityListParameters) => {
    const res = await api.GetCommunityList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }
  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true
      })
      const communityList = await this._getCommunityList({ count: 1000 })
      this.setState({
        isSpinLoading: false,
        communities: communityList.data,
      })
    } catch (error) {
      this.setState({
        isSpinLoading: false
      })
    }
  }
  render() {
    return (
      <div id="FoodCardList"
      >
        <Space align='center' >
          {/* <Button
            type='primary'
            style={{ marginBottom: 5 }}
            onClick={() => this.setState(state => ({
              editAndAddModal: { ...state.editAndAddModal, show: true, key: new Date().toString(), type: 'addFamily' }
            }))}
            icon={<PlusOutlined />}
          >
            {`追加到家庭`}
          </Button>
          <Button
            type='primary'
            style={{ marginBottom: 5 }}
            onClick={() => this.setState(state => ({
              editAndAddModal: { ...state.editAndAddModal, show: true, key: new Date().toString(), type: 'addFamilyMember' }
            }))}
            icon={<PlusOutlined />}
          >
            {`追加到成員`}
          </Button>
          <Button
            type='primary'
            style={{ marginBottom: 5 }}
            onClick={() => this.setState(state => ({
              editAndAddModal: { ...state.editAndAddModal, show: true, key: new Date().toString(), type: 'addXlsx' }
            }))}
            icon={<PlusOutlined />}
          >
            {`導入Excel`}
          </Button> */}
          <Button
            type='primary'
            style={{ marginBottom: 5 }}
            disabled={this.state.selectedRowKeys.length <= 0}
            onClick={() => this.setState(state => ({
              editAndAddModal: { ...state.editAndAddModal, show: true, key: new Date().toString(), type: 'edits', ids: this.state.selectedRowKeys }
            }))}
            icon={<EditOutlined />}
          >
            {`批量編輯`}
          </Button>
          <Button
            type='primary'
            style={{ marginBottom: 5 }}
            onClick={() => this.setState(state => ({
              foodCardDownloadModal: {
                ...state.foodCardDownloadModal,
                show: true, key: Date.now()
              }
            }))}
            icon={<DownloadOutlined />}
          >
            {`下載卡片`}
          </Button>
        </Space>

        <Modal
          maskClosable={false}
          title={(() => {
            switch (this.state.editAndAddModal.type) {
              case 'addFamily': {
                return '追加食品卡到家庭'
              }
              case 'addFamilyMember': {
                return '追加食品卡到家庭成員'
              }
              case 'addXlsx': {
                return '導入Excel'
              }
              case 'edit': {
                return '编辑食品卡'
              }
              case 'edits': {
                return '批量編輯食品卡'
              }
            }
          })()}
          visible={this.state.editAndAddModal.show}
          footer={null}
          onCancel={() => this.setState(state => ({
            editAndAddModal: { ...state.editAndAddModal, show: false }
          }))}
          width={'85%'}
        >
          <FoodCardEditAndAdd
            {...this.state.editAndAddModal}
            user={this.props.user.data}
            onFinish={async (props) => {
              this.setState(state => ({
                editAndAddModal: { ...state.editAndAddModal, show: false }
              }))
              switch (props.type) {
                case 'addXlsx': {
                  this.tableRef && await this.tableRef.refreshData()
                  message.success(`添加成功`)
                }
                  break
                case 'addFamily': {
                  this.tableRef && await this.tableRef.refreshData()
                  message.success(`添加成功`)
                }
                  break
                case 'addFamilyMember': {
                  this.tableRef && await this.tableRef.refreshData()
                  message.success(`添加成功`)
                }
                  break
                case 'edit': {
                  this.tableRef && await this.tableRef.refreshData()
                  message.success(`編輯成功`)
                }
                  break
                case 'edits': {
                  this.tableRef && await this.tableRef.refreshData()
                  message.success(`編輯成功`)
                }
                  break
              }

            }}
          />
        </Modal>

        {this.state.foodCardDetailsModal.key && (
          <FoodCardDetailsModal
            key={this.state.foodCardDetailsModal.key}
            title={'食品卡詳情頁'}
            visible={!!this.state.foodCardDetailsModal.show}
            onCancel={() => this.setState({ foodCardDetailsModal: { ...this.state.foodCardDetailsModal, show: false } })}
            foodCardCode={this.state.foodCardDetailsModal.foodCardCode}
          />
        )}

        {this.state.foodCardDownloadModal.key && (
          <FoodCardDownload
            key={this.state.foodCardDownloadModal.key}
            title={'下載卡片'}
            visible={this.state.foodCardDownloadModal.show}
            onCancel={() => this.setState(state => ({
              foodCardDownloadModal: { ...state.foodCardDownloadModal, show: false }
            }))}
            onFinish={async (props) => {
              this.setState(state => ({
                foodCardDownloadModal: { ...state.foodCardDownloadModal, show: false }
              }))
            }}
          />
        )}


        <Spin spinning={this.state.isSpinLoading}>
          {!this.state.isSpinLoading && (
            <JJ_Table<GetFoodCardRes, {
              desc: 'ascend' | 'descend'
              status?: 'NORMAL' | 'TEST'
              isEnable?: 'true' | 'false'
              type?: GetFoodCardListParameters['type'] | 'STOP'
              communityId?: string
              createRange?: [moment.Moment, moment.Moment] | null
              startRange?: [moment.Moment, moment.Moment] | null
              stopRange?: [moment.Moment, moment.Moment] | null
              validRange?: [moment.Moment, moment.Moment] | null
            }>
              tableProps={{
                rowSelection: {
                  onChange: (selectedRowKeys: any) => {
                    this.setState({ selectedRowKeys })
                  },
                  selectedRowKeys: this.state.selectedRowKeys,
                },

              }}
              ref={ref => this.tableRef = ref}
              isSearchText={true}
              sourceItems={[
                {
                  type: 'select',
                  defaultValue: 'ascend',
                  key: 'desc',
                  span: 3,
                  options: [
                    {
                      value: 'ascend',
                      name: '升序',
                      disabled: false,
                    },
                    {
                      value: 'descend',
                      name: '降序',
                      disabled: false,
                    },
                  ]
                },
                {
                  type: 'select',
                  defaultValue: 'NORMAL',
                  placeholder: '請選擇任意卡',
                  key: 'status',
                  allowClear: true,
                  span: 3,
                  options: [
                    {
                      value: 'NORMAL',
                      name: <Tag color="blue">會員卡</Tag>,
                      disabled: false,
                    },
                    {
                      value: 'TEST',
                      name: <Tag color="red">測試卡</Tag>,
                      disabled: false,
                    },
                  ]
                },
                {
                  type: 'select',
                  defaultValue: undefined,
                  placeholder: '請選擇任意社區',
                  key: 'communityId',
                  span: 5,
                  allowClear: true,
                  options: this.state.communities.map(community => ({
                    value: community.id,
                    name: <div>{community.name}  <Tag color='blue'>{community.code}</Tag></div>,
                    disabled: false,
                  }))
                },
                {
                  type: 'rangePicker',
                  key: 'createRange',
                  span: 8,
                  props: {
                    placeholder: ['創建開始時間', '創建結束時間'],
                  }
                },
                {
                  type: 'rangePicker',
                  key: 'startRange',
                  span: 7,
                  props: {
                    placeholder: ['啟動開始時間', '啟動結束時間'],
                  }
                },
                {
                  type: 'rangePicker',
                  key: 'stopRange',
                  span: 7,
                  props: {
                    placeholder: ['停用開始時間', '停用結束時間'],
                  }
                },
                {
                  type: 'rangePicker',
                  key: 'validRange',
                  span: 10,
                  props: {
                    placeholder: ['生效範圍開始時間', '生效範圍結束時間'],
                  }
                },
              ]}
              columns={[
                {
                  key: 'action',
                  width: 50,
                  render: (value: any, record) => {
                    return (
                      <JJ_TableItemAction
                        menus={[
                          {
                            type: 'item',
                            disabled: false,
                            key: 'edit',
                            name: '編輯',
                            onAction: () => {
                              this.setState(state => ({
                                editAndAddModal: { ...state.editAndAddModal, show: true, type: 'edit', id: record.id, key: new Date().toString() }
                              }))
                            }
                          },
                          {
                            type: 'item',
                            disabled: false,
                            key: 'showDetails',
                            name: '顯示詳情',
                            onAction: () => {
                              this.setState(state => ({
                                foodCardDetailsModal: { ...state.foodCardDetailsModal, show: true, key: new Date().toString(), foodCardCode: record.code }
                              }))
                            }
                          },
                        ]}
                      />
                    )

                  },
                },
                {
                  title: 'ID',
                  dataIndex: 'id',
                  key: 'id',
                  width: 50,
                  render: (value) => (
                    <JJ_TableItemCopyableId
                      text={value}
                    />
                  )
                },

                {
                  title: '代號',
                  dataIndex: 'code',
                  key: 'code',
                },
                {
                  title: '社區名稱',
                  dataIndex: ['community', 'name'],
                  key: 'community.name',
                },

                {
                  title: '狀態',
                  dataIndex: 'foodCardStatus',
                  key: 'foodCardStatus',
                  width: 150,
                  render: (value, record) => {

                    if (!record) {
                      return
                    }

                 

                    if (record.isEnable !== undefined && !record.isEnable) {
                      return <Tag color="red">已強制停用</Tag>
                    }

                    if (!record.endAt || !record.beginAt) {
                      return <Tag color="red">未設置</Tag>
                    }

                    if (record.beginAt && moment().isBefore(moment(record.beginAt), 'day')) {
                      //如果現在時間小於開始時間  未開始
                      // 20 < 21
                      return <Tag color="red">未開始</Tag>
                    }

                    if (record.endAt && moment().isAfter(moment(record.endAt))) {
                      //如果現在時間小於開始時間  未開始
                      //20 >= 19
                      return <Tag color="red">已結束</Tag>
                    }
                 
                    return <Tag color="blue">生效中</Tag>

                  }
                },

                {
                  title: '是否啟用',
                  dataIndex: 'isEnable',
                  key: 'isEnable',
                  render: (value, record) => {
                    return (
                      record.isEnable
                        ? <Tag color="blue">啟用</Tag>
                        : <Tag color="red">停用</Tag>
                    )
                  }
                },
                {
                  title: '啟用時間',
                  dataIndex: 'beginAt',
                  key: 'beginAt',
                  render: (value) =>
                    value
                      ? (<JJ_TableItemMoment
                        date={value}
                      />)
                      : '--:--'
                },
                {
                  title: '停用時間',
                  dataIndex: 'endAt',
                  key: 'endAt',
                  render: (value) =>
                    value
                      ? (<JJ_TableItemMoment
                        date={value}
                      />)
                      : '--:--'
                },
                {
                  title: '創建時間',
                  dataIndex: 'createdAt',
                  key: 'createdAt',
                  render: (value) => (
                    <JJ_TableItemMoment
                      date={value}
                    />
                  )
                },
              ]}
              title={'食品卡清單'}
              onDataSource={async (body) => {
                const { sourceItemBody } = body
                const params: {
                  isAscend?: GetFoodCardListParameters['isAscend']
                  status?: GetFoodCardListParameters['status']
                  isEnable?: GetFoodCardListParameters['isEnable']
                  scope?: GetFoodCardListParameters['scope']
                  type?: GetFoodCardListParameters['type']
                  communityId?: GetFoodCardListParameters['communityId']
                  createRangeBeginAt?: GetFoodCardListParameters['createRangeBeginAt']
                  createRangeEndAt?: GetFoodCardListParameters['createRangeEndAt']
                  startRangeBeginAt?: GetFoodCardListParameters['startRangeBeginAt']
                  startRangeEndAt?: GetFoodCardListParameters['startRangeEndAt']
                  stopRangeBeginAt?: GetFoodCardListParameters['stopRangeBeginAt']
                  stopRangeEndAt?: GetFoodCardListParameters['stopRangeEndAt']
                  validRangeBeginAt?: GetFoodCardListParameters['validRangeBeginAt']
                  validRangeEndAt?: GetFoodCardListParameters['validRangeEndAt']
                } = {}
                if (sourceItemBody) {
                  if (sourceItemBody.desc) {
                    params.isAscend = sourceItemBody.desc === 'ascend' ? true : false
                  }
                  params.status = sourceItemBody.status
                  params.communityId = sourceItemBody.communityId
                  if (sourceItemBody.isEnable) {
                    params.isEnable = sourceItemBody.isEnable === 'true'
                    this.setState({
                      foodCardIsEnable: params.isEnable,
                    })
                  }

                  const createRange = sourceItemBody.createRange
                  if (createRange && createRange.length === 2) {
                    const [createRangeBeginAtMoment, createRangeEndAtMoment,] = createRange
                    params.createRangeBeginAt = moment(createRangeBeginAtMoment).startOf('day').toISOString()
                    params.createRangeEndAt = moment(createRangeEndAtMoment).endOf('day').toISOString()
                  }
                  const startRange = sourceItemBody.startRange
                  if (startRange && startRange.length === 2) {
                    const [startRangeBeginAtMoment, startRangeEndAtMoment,] = startRange
                    params.startRangeBeginAt = moment(startRangeBeginAtMoment).startOf('day').toISOString()
                    params.startRangeEndAt = moment(startRangeEndAtMoment).endOf('day').toISOString()
                  }
                  const stopRange = sourceItemBody.stopRange
                  if (stopRange && stopRange.length === 2) {
                    const [stopRangeBeginAtMoment, stopRangeEndAtMoment,] = stopRange
                    params.stopRangeBeginAt = moment(stopRangeBeginAtMoment).startOf('day').toISOString()
                    params.stopRangeEndAt = moment(stopRangeEndAtMoment).endOf('day').toISOString()
                  }

                  const validRange = sourceItemBody.validRange
                  if (validRange && validRange.length === 2) {
                    const [validRangeBeginAtMoment, validRangeEndAtMoment,] = validRange
                    params.validRangeBeginAt = moment(validRangeBeginAtMoment).startOf('day').toISOString()
                    params.validRangeEndAt = moment(validRangeEndAtMoment).endOf('day').toISOString()
                  }

                  if (sourceItemBody.type === 'STOP') {
                    params.isEnable = false
                  } else {
                    params.type = sourceItemBody.type
                  }
                }
                params.scope = ['community']
                const res = await api.GetFoodCardList({
                  ...body,
                  ...params,
                })
                if (res.kind !== 'ok') throw new Error(res.kind)
                return res.data
              }}
            />
          )}
        </Spin>

      </div>
    )
  }

}
export default connector(FoodCardList)



