import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetSignupFormFamilyMemberRes, GetSignupFormFamilyMemberParameters, PutSignupFormFamilyMemberParameters } from '../../api';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button, Typography, Input, Select, Tag, DatePicker } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';
import moment from 'moment';

const { Paragraph } = Typography;
const { TextArea } = Input;

interface PageState {
  isSpinLoading: boolean
}

interface Props {
  show: boolean
  onOk: () => void
  onCancel: () => void
  id: string
}

interface Page {
}

type PageProps = Props

class SignupFormDetailsModalFamilyMemberModal extends React.Component<PageProps, PageState> implements Page {

  formRef: (
    FormInstance<{
    }> | null
  ) | undefined

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: false,
    }
  }
  componentDidMount() {
  }

  _formListData = (): FormItemData[] => {
    return [
      {
        id: 'lastname',
        label: '英文姓氏',
        rules: [
          {
            required: false,
            message: `請輸入 英文姓氏`
          }
        ],
      },
      {
        id: 'firstname',
        label: '英文名字',
        rules: [
          {
            required: false,
            message: `請輸入 英文名字`
          }
        ],
      },
      {
        id: 'fullNameChinese',
        label: '中文全名',
        rules: [
          {
            required: false,
            message: `請輸入 中文全名`
          }
        ],
      },
      {
        id: 'gender',
        label: '性別',
        rules: [
          {
            required: false,
            message: `請輸入 性別`
          }
        ],
        componet: (
          <Select
            allowClear={true}
            showSearch={true}
            filterOption={(input: any, option) => {
              if (option && option.key && typeof (option.key) === 'string') {
                return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              return false
            }}
          >
            {
              [
                { key: 'F', color: 'pink', title: '女' },
                { key: 'M', color: 'blue', title: '男' },
              ].map(item => {
                return (
                  <Select.Option
                    key={item.key}
                    value={item.key}
                  >
                    <Tag
                      color={item.color}
                    >
                      {item.title}
                    </Tag>
                  </Select.Option>
                )
              })
            }

          </Select>
        ),
      },
      {
        id: 'dateOfBirth',
        label: '出身日期',
        rules: [
          {
            required: false,
            message: `請選擇 出身日期`
          }
        ],
        componet: (
          <DatePicker picker='date' />
        ),
      },
      {
        id: 'hkidPrefix',
        label: 'HKID前四位',
        rules: [
          {
            required: false,
            message: `請輸入 HKID前四位`
          }
        ],
      },
    ]
  }

  _onFinish = async (values: any): Promise<void> => {

    this.setState({
      isSpinLoading: true,
    })

    console.log('_onFinish', values)

    const res = await this._putSignupFormFamilyMember({
      ...values,
      dateOfBirth: values.dateOfBirth ? moment(values.dateOfBirth).startOf('day').toISOString() : undefined,
      id: this.props.id,
    })

    this.props.onOk()
  }

  _onLoadData = async (): Promise<any> => {
    const res = await this._getSignupFormFamilyMember({ id: this.props.id })
    return {
      ...res,
      dateOfBirth: res.dateOfBirth ? moment(res.dateOfBirth) : null
    }
  };

  _getSignupFormFamilyMember = async (param: GetSignupFormFamilyMemberParameters) => {
    const res = await api.GetSignupFormFamilyMember(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _putSignupFormFamilyMember = async (param: PutSignupFormFamilyMemberParameters) => {
    const res = await api.PutSignupFormFamilyMember(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  render() {
    return (
      <Modal
        visible={this.props.show}
        title="編輯家庭成員"
        maskClosable={false}
        onOk={() => this.formRef && this.formRef.submit()}
        onCancel={() => this.props.onCancel()}
        okButtonProps={{ disabled: this.state.isSpinLoading }}
        width="80%"
      >
        <Spin spinning={this.state.isSpinLoading} style={{ width: '100%' }}>
          <JJ_From
            formRef={res => this.formRef = res}
            formListData={this._formListData()}
            onLoadData={this._onLoadData}
            onFinish={this._onFinish}
          />
        </Spin>
      </Modal>
    )
  }

}
export default SignupFormDetailsModalFamilyMemberModal

