import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetSignupFormFamilyMemberListParameters, GetSignupFormSalesforceCasesRes, GetSignupFormFamilyMemberRes, GetSignupFormParameters, GetSignupFormRes } from '../../api';
import JJ_TableItemCopyableId from '../../components/JJ_TableItemCopyableId';
import JJ_Table from '../../components/JJ_Table';
import { Modal, message, Spin } from 'antd';
import { EditOutlined, CheckOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';

interface PageState {
  isSpinLoading: boolean
  signupForm?: GetSignupFormRes
  members: {
    member: GetSignupFormFamilyMemberRes
    items: GetSignupFormSalesforceCasesRes['items']
    isPass: boolean
  }[]
}

interface Props {
  show: boolean
  onCancel: () => void
  id: string
}

interface Page {
}

type PageProps = Props

class SignupFormDetailsModalHistoryModal extends React.Component<PageProps, PageState> implements Page {

  formRef: (
    FormInstance<{
    }> | null
  ) | undefined

  constructor(props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
      members: [],
    }
  }
  componentDidMount() {
    this._onLoadData()
  }

  _onLoadData = async () => {
    this.setState({
      isSpinLoading: true,
    })

    const signupFormRes = await this._getSignupForm({
      id: this.props.id,
    });

    const memberRes = await this._getSignupFormFamilyMemberList({
      signupFormId: this.props.id,
      isAscend: false,
    });

    const members: PageState['members'] = []
    for (let member of memberRes.data) {
      const itemRes = await api.GetSignupFormSalesforceCases({
        firstname: member.firstname || '',
        lastname: member.lastname || '',
        hkidPrefix: member.hkidPrefix || '',
      })

      if (itemRes.kind === 'ok') {
        members.push({
          member,
          items: itemRes.data.items,
          isPass: true,
        })
      }
    }

    this.setState({
      members,
      signupForm: signupFormRes,
      isSpinLoading: false,
    })
  }

  _getSignupFormFamilyMemberList = async (params: GetSignupFormFamilyMemberListParameters) => {
    const res = await api.GetSignupFormFamilyMemberList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getSignupForm = async (params: GetSignupFormParameters) => {
    const res = await api.GetSignupForm(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  render() {
    return (
      <Modal
        visible={this.props.show}
        title="檢查個案歷史"
        onCancel={() => this.props.onCancel()}
        width="60%"
        footer={null}
      >
        {this.state.isSpinLoading ? (
          <Spin spinning={true} />
        ) : this.state.members.map(member => (
          <div key={member.member.id}>
            <JJ_Table
              columns={[
                {
                  title: 'ID',
                  dataIndex: 'id',
                  key: 'id',
                  width: 50,
                  render: (value) => (
                    <JJ_TableItemCopyableId
                      text={value}
                    />
                  )
                },
                {
                  title: '當前個案',
                  dataIndex: 'iscurrent',
                  key: 'iscurrent',
                  width: 50,
                  render: (value, record) => this.state.signupForm && record.id === this.state.signupForm.caseId && <CheckOutlined style={{color: '#0C0'}} />
                },
                {
                  title: '狀態',
                  dataIndex: 'status',
                  key: 'status',
                  render: (value, record) => {
                    return value || '-'
                  }
                },
                {
                  title: '已選服務',
                  dataIndex: 'selectedService',
                  key: 'selectedService',
                  render: (value, record) => {
                    return value || '-'
                  }
                },
                {
                  title: '已分配服務',
                  dataIndex: 'assignedService',
                  key: 'assignedService',
                  render: (value, record) => {
                    return value || '-'
                  }
                },
              ]}
              title={`${member.member.lastname} ${member.member.firstname}${!!member.member.fullNameChinese ? ` (${member.member.fullNameChinese})` : ''}`}
              onDataSource={async (body) => {
                return {
                  totalCount: member.items.length,
                  data: member.items,
                }
              }}
            />
          </div>
        ))}
      </Modal>
    )
  }

}
export default SignupFormDetailsModalHistoryModal

