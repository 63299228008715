import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button, Typography, Input } from 'antd';
import api, { PutSignupFormParameters } from '../../api';
import { EditOutlined } from '@ant-design/icons';

const {Paragraph} = Typography;
const {TextArea} = Input;

interface PageState {
  isSpinLoading: boolean
  editorModal: {
    show: boolean
    key: any
    value?: string
  }
}

interface Props {
  value?: string
  onChange: (value: any) => void
  signupFormId: string
}

interface Page {
}

type PageProps = Props

class SignupFormDetailsModalRemarkEditor extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: false,
      editorModal: {
        show: false,
        key: new Date().toString(),
        value: '',
      },
    }
  }
  componentDidMount() {
  }

  _putSignupForm = async (param: PutSignupFormParameters) => {
    const res = await api.PutSignupForm(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _onOk = async (): Promise<void> => {
    this.setState({
      isSpinLoading: true,
    })

    const res = await this._putSignupForm({
      id: this.props.signupFormId,
      remark: this.state.editorModal.value,
    })

    this.setState({
      isSpinLoading: false,
      editorModal: {
        ...this.state.editorModal,
        show: false,
      },
    })

    this.props.onChange(this.state.editorModal.value)

    message.success('已更新備註')
  }

  render() {
    return (
      <>
        <Modal
          key={this.state.editorModal.key}
          visible={this.state.editorModal.show}
          title="編輯備註"
          maskClosable={false}
          onOk={this._onOk}
          onCancel={() => {
            this.setState({
              editorModal: {
                ...this.state.editorModal,
                show: false,
              },
            })
          }}
          okButtonProps={{disabled: this.state.isSpinLoading}}
        >
          <Spin spinning={this.state.isSpinLoading}>
            <TextArea
              rows={10}
              value={this.state.editorModal.value}
              onChange={e => {
                this.setState({
                  editorModal: {
                    ...this.state.editorModal,
                    value: e.target.value,
                  },
                })
              }}
            />
          </Spin>
        </Modal>

        <Paragraph
          style={{whiteSpace: 'pre-wrap'}}
        >
          {this.props.value}
        </Paragraph>
        <Button
          type="default"
          shape="round"
          icon={<EditOutlined />}
          onClick={() => {
            this.setState({
              editorModal: {
                ...this.state.editorModal,
                show: true,
                value: this.props.value,
              },
            })
          }}
        >
          編輯
        </Button>
      </>
    )
  }

}
export default SignupFormDetailsModalRemarkEditor

