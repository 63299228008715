export const logo = require("./logo.png")
export const logoAfds = require("./logo-afds.png")
export const logoFoodAngel = require("./logo-food-angel.png")
export const logoFoodAngelSit = require("./logo-food-angel-sit.png")
export const logoFoodAngelUat = require("./logo-food-angel-uat.png")
export const logoHkjcAllProject = require("./logo-hkjc-all-project.png")
export const logoHkjc = require("./logo-hkjc.png")
export const notData = require("./not-data.png")


