import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button, Tag, Space, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import api, { GetMessageListParameters, DeleteMessageParameters, GetMessageRes, GetUserRes } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import JJ_TableItemAction from '../../components/JJ_TableItemAction';
import JJ_TableItemMoment from '../../components/JJ_TableItemMoment';
import MessageEditAndAdd, { MessageEditAndAddPropsType } from './MessageEditAndAdd';
import { PlusOutlined } from '@ant-design/icons';
import { connect, ConnectedProps } from 'react-redux';
import JJ_TableItemCopyableId from '../../components/JJ_TableItemCopyableId';
import config from '../../config';
import moment from 'moment';
import { ActionUserRes } from '../../actions/ActionUser';
import { getI18nText } from '../../public';
import { ColumnsType } from 'antd/lib/table';
import MessageRecordList from '../messageRecord/MessageRecordList';


const { confirm } = Modal;
const { Paragraph } = Typography;

type EditAndAddModalType = MessageEditAndAddPropsType

interface PageState {
  isSpinLoading: boolean
  editAndAddModal: {
    show: boolean
    key: string
  } & EditAndAddModalType

  messageRecordListModal?: {
    show: boolean
    key: string
    messageId: string
  }
}
interface Props {

}


interface Page {
  tableRef?: JJ_Table<GetMessageRes> | null

}


type PageProps = Props & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes

}

const mapState = (state: RootState) => ({
  user: state.user,
})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class MessageList extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
      editAndAddModal: {
        type: 'add',
        show: false,
        key: new Date().toString(),
      }
    }
  }
  tableRef?: JJ_Table<GetMessageRes> | null
  componentDidMount() {
    this._initData()
  }
  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true
      })
      await Promise.resolve()
      this.setState({
        isSpinLoading: false
      })
    } catch (error) {
      this.setState({
        isSpinLoading: false
      })
    }
  }


  _deleteMessage = async (params: DeleteMessageParameters) => {
    const res = await api.DeleteMessage(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _getTypeView = (type: GetMessageRes['type']) => {

    switch (type) {
      case 'system': {
        return <Tag color='blue'>系統訊息</Tag>
      }
      case 'user': {
        return <Tag color='green'>個人訊息</Tag>
      }
      default: {
        return <div />
      }
    }
  }



  _getColumns = (): ColumnsType<GetMessageRes> => {

    return [
      {
        key: 'action',
        width: 50,
        fixed: 'left',
        render: (value: any, record) => {
          return (
            <JJ_TableItemAction
              menus={[
                {
                  type: 'item',
                  disabled: false,
                  key: 'edit',
                  name: '編輯',
                  onAction: () => {
                    this.setState(state => ({
                      editAndAddModal: { ...state.editAndAddModal, show: true, type: 'edit', id: record.id, key: new Date().toString() }
                    }))
                  }
                },
                {
                  type: 'item',
                  disabled: false,
                  key: '查看App系統消息記錄列表',
                  name: '查看App系統消息記錄列表',
                  onAction: () => {
                    this.setState({
                      messageRecordListModal: {
                        show: true,
                        key: new Date().toString(),
                        messageId: record.id
                      }
                    })

                  }
                },
                {
                  type: 'item',
                  disabled: config.REACT_APP_DELETE_DISABLED,
                  key: 'delete',
                  name: '删除',
                  onAction: () => {
                    confirm({
                      title: `是否刪除${getI18nText(record.title, this.props.user.data.language)}`,
                      icon: <ExclamationCircleOutlined />,
                      okText: 'Yes',
                      okType: 'danger',
                      cancelText: 'No',
                      onOk: async () => {
                        try {
                          await this._deleteMessage({ id: record.id })
                          this.tableRef && this.tableRef.refreshData()
                          message.success(`${getI18nText(record.title, this.props.user.data.language)} 删除成功`)
                        } catch (error) {
                          message.error(`${getI18nText(record.title, this.props.user.data.language)} 删除失败 [${error.message}]`);
                        }
                      },
                      onCancel() {
                      },
                    });
                  }
                },
              ]}
            />
          )

        },
      },
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 150,
        render: (value) => (
          <JJ_TableItemCopyableId
            text={value}
          />
        )
      },

      {
        title: '標題',
        dataIndex: 'title',
        key: 'title',
        width: 200,
        render: (value, record) => <div style={{
          maxHeight: 80,
        }}>{getI18nText(record.title, this.props.user.data.language)}</div>
      },
      {
        title: '內容',
        dataIndex: 'content',
        key: 'content',
        width: 300,
        render: (value, record) => {
          return (
            <Paragraph
              ellipsis={{
                rows: 1,
                expandable: true,
              }}
              style={{
                whiteSpace: 'pre-wrap',
              }}
            >
              {getI18nText(record.content, this.props.user.data.language)}
            </Paragraph>
          )
        }
      },
      {
        title: '鏈接',
        dataIndex: 'linkUrl',
        key: 'linkUrl',
        width: 200,
        render: (value, record) => (

          <div
            style={{
              width: 180,
              maxHeight: 80,
            }}
          >
            <a
              href={record.linkUrl}
              target="_blank"
            >{record.linkUrl}</a>
          </div>

        )
      },
      {
        title: '類型',
        dataIndex: 'type',
        key: 'type',
        width: 150,
        render: (value, record) => this._getTypeView(record.type),
      },
      {
        title: '創建者',
        dataIndex: 'user',
        key: 'user',
        width: 150,
        render: (value, record) => (record.user as GetUserRes)?.nickname
      },
      {
        title: '備註',
        dataIndex: 'remark',
        key: 'remark',
        width: 200,
      },
      {
        title: '創建時間',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 120,
        fixed: 'right',
        render: (value) => (
          <JJ_TableItemMoment
            date={value}
          />
        )
      },
    ]
  }

  render() {

    const messageRecordListModal = this.state.messageRecordListModal

    return (
      <div id="MessageList"
      >
        <Button
          type='primary'
          style={{ marginBottom: 5 }}
          onClick={() => {
            this.setState(state => ({
              editAndAddModal: {
                ...state.editAndAddModal,
                show: true,
                key: new Date().toString(),
                type: 'add',
              }
            }))
          }}
          icon={<PlusOutlined />}
        >
          {`添加App系統消息`}
        </Button>

        <Modal
          maskClosable={false}
          title={this.state.editAndAddModal.type === 'edit'
            ? '編輯App系統消息'
            : '新建App系統消息'
          }
          visible={this.state.editAndAddModal.show}
          footer={null}
          onCancel={() => this.setState(state => ({
            editAndAddModal: { ...state.editAndAddModal, show: false }
          }))}
          width={'85%'}
        >
          <MessageEditAndAdd
            {...this.state.editAndAddModal}
            onFinish={async (props, id) => {
              this.setState(state => ({
                editAndAddModal: { ...state.editAndAddModal, show: false }
              }))
              switch (props.type) {
                case 'add': {


                  this.setState({
                    messageRecordListModal: {
                      show: true,
                      key: new Date().toString(),
                      messageId: id,
                    }
                  })

                  message.success(`添加成功`)

                }
                  break
                case 'edit': {
                  message.success(`編輯成功`)
                }
                  break

              }

              this.tableRef && await this.tableRef.refreshData()


            }}
          />
        </Modal>


        {messageRecordListModal && messageRecordListModal.key && (
          <Modal
            key={messageRecordListModal.key}
            maskClosable={false}
            // title={''}
            visible={messageRecordListModal.show}
            footer={null}
            onCancel={() => this.setState({
              messageRecordListModal: { ...messageRecordListModal, show: false }
            })}
            width={'85%'}
          >
            <MessageRecordList
              messageId={messageRecordListModal.messageId}
            />
          </Modal>
        )}



        <Spin spinning={this.state.isSpinLoading}>
          {!this.state.isSpinLoading && (
            <JJ_Table<GetMessageRes, {
              desc: 'ascend' | 'descend'
              createRange?: [moment.Moment, moment.Moment] | null
              type?: GetMessageListParameters['type']
            }>
              ref={ref => this.tableRef = ref}
              isSearchText={true}
              sourceItems={[
                {
                  type: 'select',
                  defaultValue: 'ascend',
                  key: 'desc',
                  options: [
                    {
                      value: 'ascend',
                      name: '升序',
                      disabled: false,
                    },
                    {
                      value: 'descend',
                      name: '降序',
                      disabled: false,
                    },
                  ]
                },
                {
                  type: 'rangePicker',
                  key: 'createRange',
                  span: 8,
                  props: {
                    placeholder: ['開始時間', '結束時間'],
                  }
                },

                {
                  type: 'select',
                  defaultValue: undefined,
                  placeholder: '請選擇任意類型',
                  key: 'type',
                  allowClear:true,
                  options: [
                    'user',
                    'system',
                  ].map((item: any) => ({
                    value: item,
                    name: this._getTypeView(item),
                    disabled: false,
                  }))
                },
              ]}

              columns={this._getColumns()}
              title={'App系統消息列表'}
              tableProps={{
                scroll: {
                  x: this._getColumns().reduce((pValue, cValue) => pValue + (Number(cValue.width) || 0), 0)
                }
              }}
              onDataSource={async (body) => {
                const { sourceItemBody } = body
                const params: {
                  isAscend?: GetMessageListParameters['isAscend']
                  beginAt?: GetMessageListParameters['beginAt']
                  endAt?: GetMessageListParameters['endAt']
                  type?: GetMessageListParameters['type']

                } = {}
                if (sourceItemBody) {
                  if (sourceItemBody.type) {
                    params.type = sourceItemBody.type
                  }

                  if (sourceItemBody.desc) {
                    params.isAscend = sourceItemBody.desc === 'ascend' ? true : false
                    const createRange = sourceItemBody.createRange
                    if (createRange && createRange.length === 2) {
                      const [createRangeBeginAtMoment, createRangeEndAtMoment,] = createRange
                      params.beginAt = moment(createRangeBeginAtMoment).startOf('day').toISOString()
                      params.endAt = moment(createRangeEndAtMoment).endOf('day').toISOString()
                    }
                  }

                }

                const res = await api.GetMessageList({
                  ...body,
                  ...params,
                  scope: ['user'],
                })
                if (res.kind !== 'ok') throw new Error(res.kind)
                return res.data
              }}
            />
          )}
        </Spin>

      </div>
    )
  }

}
export default connector(MessageList)

