import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button, Tag } from 'antd';
import api, { GetKioskVendingListParameters, GetKioskVendingRes, GetStatisticKioskVendingMemberMonthParameters, GetStatisticKioskVendingMemberMonthDataRes } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { connect, ConnectedProps } from 'react-redux';
import JJ_TableItemCopyableId from '../../components/JJ_TableItemCopyableId';
import moment from 'moment';
import KioskVendingSellDayListModal from './KioskVendingSellDayListModal';
import JJ_TableItemAction from '../../components/JJ_TableItemAction';
import KioskVendingMemberMonthStatsModal from './KioskVendingMemberMonthStatsModal';
const { confirm } = Modal;

interface PageState {
  isSpinLoading: boolean
  kioskVendingMemberMonthStatsModalProps: {
    key?: any
    title: string
    visible: boolean
    kioskVendingId: string
    beginAt: moment.Moment
  }
}
interface Props {
}


interface Page {


}


type PageProps = Props & RouteChildrenProps & ConnectedProps<typeof connector>

interface RootState {

}

const mapState = () => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class KioskVendingMemberMonthList extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
      kioskVendingMemberMonthStatsModalProps: {
        title: '查看取餐機會員每月統計報告',
        visible: false,
        kioskVendingId: '',
        beginAt: moment()
      },
    }
  }
  tableRef?: JJ_Table<GetStatisticKioskVendingMemberMonthDataRes & { id: string }> | null


  componentDidMount() {
    this._initData()
  }
  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true
      })
      await Promise.resolve()
      this.setState({
        isSpinLoading: false
      })
    } catch (error) {
      this.setState({
        isSpinLoading: false
      })
    }
  }
  render() {
    return (
      <div id="KioskVendingMemberMonthList"
      >

        {this.state.kioskVendingMemberMonthStatsModalProps.key && (
          <KioskVendingMemberMonthStatsModal
            {...this.state.kioskVendingMemberMonthStatsModalProps}
            onCancel={() => {
              this.setState({ kioskVendingMemberMonthStatsModalProps: { ...this.state.kioskVendingMemberMonthStatsModalProps, visible: false } })
            }}
          />
        )}

        <Spin spinning={this.state.isSpinLoading}>
          {!this.state.isSpinLoading && (
            <JJ_Table<GetStatisticKioskVendingMemberMonthDataRes & { id: string }, {
              desc: 'ascend' | 'descend'
              beginAt: moment.Moment
            }>
              ref={ref => this.tableRef = ref}
              isSearchText={true}
              sourceItems={[

                {
                  type: 'select',
                  defaultValue: 'ascend',
                  key: 'desc',
                  options: [
                    {
                      value: 'ascend',
                      name: '升序',
                      disabled: false,
                    },
                    {
                      value: 'descend',
                      name: '降序',
                      disabled: false,
                    },
                  ]
                },
                {
                  type: 'datePicker',
                  key: 'beginAt',
                  defaultValue: moment(),
                  props: {
                    picker: 'month',
                    disabledDate: (current) => current && current > moment().add('day', 1).startOf('day'),
                  }
                },
              ]}
              columns={[
                {
                  key: 'action',
                  width: 50,
                  render: (value: any, record) => {
                    return (
                      <JJ_TableItemAction
                        menus={[
                          {
                            type: 'item',
                            disabled: false,
                            key: 'edit',
                            name: this.state.kioskVendingMemberMonthStatsModalProps.title,
                            onAction: () => {
                              this.setState(state => ({
                                kioskVendingMemberMonthStatsModalProps: { ...state.kioskVendingMemberMonthStatsModalProps, visible: true, key: Date.now(), kioskVendingId: record.kioskVending.id }
                              }))
                            }
                          },
                        ]}
                      />
                    )

                  },
                },
                {
                  title: 'ID',
                  dataIndex: 'id',
                  key: 'id',
                  width: 50,
                  render: (value) => (
                    <JJ_TableItemCopyableId
                      text={value}
                    />
                  )
                },
                {
                  title: '名稱',
                  dataIndex: ['kioskVending', 'name'],
                  key: 'name',
                },
                {
                  title: '代號',
                  dataIndex: ['kioskVending', 'code'],
                  key: 'code',
                },
                {
                  title: '有效會員數量',
                  dataIndex: ['memberTotalCount'],
                  key: 'memberTotalCount',
                },
                {
                  title: '有效會員取餐平均值',
                  dataIndex: ['memberAverageRate'],
                  key: 'memberAverageRate',
                  render: (value, recode) => {

                    return (
                      <span style={{
                        color: recode.memberAverageRate > 0.8
                          ? 'green'
                          : recode.memberAverageRate > 0.3
                            ? 'orange'
                            : 'red'
                      }}>
                        {Number((recode.memberAverageRate * 100).toFixed(2))}%
                      </span>
                    )
                  },
                },
              ]}
              count = {500}
              title={'取餐機清單'}
              onDataSource={async (body) => {
                const { sourceItemBody } = body
                const params: {
                  isAscend?: GetStatisticKioskVendingMemberMonthParameters['isAscend']
                  scope?: GetStatisticKioskVendingMemberMonthParameters['scope']
                  beginAt?: GetStatisticKioskVendingMemberMonthParameters['beginAt']
                } = {}
                if (sourceItemBody) {
                  if (sourceItemBody.desc) {
                    params.isAscend = sourceItemBody.desc === 'ascend' ? true : false
                  }
                  if (sourceItemBody.beginAt) {
                    params.beginAt = moment(sourceItemBody.beginAt).startOf('day').toISOString()
                    this.setState({
                      kioskVendingMemberMonthStatsModalProps: { ...this.state.kioskVendingMemberMonthStatsModalProps, beginAt: sourceItemBody.beginAt }
                    })
                  }
                }
                params.scope = ['community']
                const res = await api.GetStatisticKioskVendingMemberMonth({
                  ...body,
                  ...params,
                })
                if (res.kind !== 'ok') throw new Error(res.kind)
                return {
                  ...res.data,
                  data: res.data.data.map(item => ({ ...item, id: item.kioskVending.id }))
                }
              }}
            />
          )}
        </Spin>

      </div>
    )
  }

}
export default connector(KioskVendingMemberMonthList)

