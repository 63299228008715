import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetSignupFormFamilyMemberRes, GetSignupFormFamilyMemberParameters, PutSignupFormFamilyMemberParameters, PutSignupFormParameters } from '../../api';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button, Typography, Input, Select, Tag, DatePicker } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';
import { ifError } from 'assert';

const { Paragraph } = Typography;
const { TextArea } = Input;
const { Option } = Select;

interface PageState {
  isSpinLoading: boolean
  reasonParentCode?: string
}

interface Props {
  show: boolean
  onOk: () => void
  onCancel: () => void
  id: string
}

interface Page {
}

type PageProps = Props

class SignupFormDetailsModalNotApprovedModal extends React.Component<PageProps, PageState> implements Page {

  formRef: (
    FormInstance<{
    }> | null
  ) | undefined

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: false,
    }
  }
  componentDidMount() {
  }

  _getReasonSubDatas = () => {
    const data = [
      {
        reasonParentCode: 'Service_Failed_to_Cooperate',
        data: [
          {
            value: 'Meal_Taking_Distance_Limit',
            label: '取餐距離限制',
          },
          {
            value: 'Meal_Taking_Time_Limit',
            label: '取餐時間限制',
          },
          {
            value: 'Failure_to_Meet_Dietary_Requirements',
            label: '未符膳食要求',
          },
          {
            value: 'The_Applicant_does_not_want_to_Wait',
            label: '服務暫時額滿，申請人不想等候',
          },
          {
            value: 'Unknown_Other',
            label: '未知／其他',
          },
        ],
      },

      {
        reasonParentCode: 'Application_Qualification',
        data: [
          {
            value: 'Inconsistent_Income_Level',
            label: '入息水平不符',
          },
          {
            value: 'Inconsistent_Asset_Level',
            label: '資產水平不符',
          },
          {
            value: 'Unrelated_to_the_Epidemic_or_Chronic_Poverty_or_Retirees',
            label: '與疫情無關/長期貧窮/退休人士',
          },
          {
            value: 'Unknown_Other',
            label: '未知／其他',
          },
        ],
      },

      {
        reasonParentCode: 'Failed_to_Contact_Applicant',
        data: [
          {
            value: 'Wrong_Contact_Method',
            label: '錯誤聯絡方法',
          },
          {
            value: 'Unable_to_Contact_for_Many_Times',
            label: '多次無法聯絡',
          },
          {
            value: 'Unknown_Other',
            label: '未知／其他',
          },
        ],
      },
      {
        reasonParentCode: 'Failed_to_Complete_the_Application_Procedure',
        data: [
          {
            value: 'Did_not_Attend_the_Approval_Meeting',
            label: '未出席審批會面',
          },
          {
            value: 'Incomplete_Application_Documents',
            label: '申請文件不完全',
          },
          {
            value: 'Unknown_Other',
            label: '未知／其他',
          },
        ],
      },
      {
        reasonParentCode: 'Withdrawal_of_Application',
        data: [
          {
            value: 'Improved_Living_Conditions_or_Employed',
            label: '生活情況改善／已就業',
          },
          {
            value: 'Repeat_Application_or_will_Reapply',
            label: '重複申請／將重新申請',
          },
          {
            value: 'Applied_or_Ready_to_Apply_for_Other_Services',
            label: '已申請／準備申請其他服務',
          },
          {
            value: 'Family_Related_Reasons',
            label: '家庭相關原因／家人不願提供資料',
          },
          {
            value: 'Unknown_Other',
            label: '未知／其他',
          },
        ],
      },

      {
        reasonParentCode: 'Services_not_Expected_by_the_Applicant',
        data: [
          {
            value: 'Unknown_Reason',
            label: '未知原因',
          },
        ],
      },
      {
        reasonParentCode: 'Unknown_Other',
        data: [
          {
            value: 'Unknown_Reason',
            label: '未知原因',
          },
        ],
      },

    ]
      .find(item => item.reasonParentCode === this.state.reasonParentCode)

    return data?.data

  }

  _formListData = (): FormItemData[] => {

    const reasonSubDatas = this._getReasonSubDatas()

    return [
      {
        id: 'reasonParentCode',
        label: '拒絕原因',
        rules: [
          {
            required: true,
            message: `請選擇 拒絕原因`
          }
        ],
        componet: (
          <Select>
            {[
              {
                value: 'Service_Failed_to_Cooperate',
                label: '服務未能配合',
              },
              {
                value: 'Application_Qualification',
                label: '申請資格',
              },
              {
                value: 'Failed_to_Contact_Applicant',
                label: '未能聯絡申請者',
              },
              {
                value: 'Failed_to_Complete_the_Application_Procedure',
                label: '未能完成申請程序',
              },
              {
                value: 'Withdrawal_of_Application',
                label: '撤回申請',
              },
              {
                value: 'Services_not_Expected_by_the_Applicant',
                label: '服務非申請者期望選擇',
              },
              {
                value: 'Unknown_Other',
                label: '未知 / 其他',
              },
            ].map(option => (
              <Option key={option.value} value={option.value}>{option.label}</Option>
            ))}
          </Select>
        ),
      },
      ...(reasonSubDatas ? [
        {
          id: 'reasonSubCode',
          label: '拒絕子原因',
          rules: [
            {
              required: true,
              message: `請選擇 拒絕子原因`
            }
          ],
          componet: (
            <Select>
              {reasonSubDatas
                .map(option => (
                  <Option key={option.value} value={option.value}>{option.label}</Option>
                ))}
            </Select>
          ),
        },

      ] : []),
    ]
  }

  _onFinish = async (values: any): Promise<void> => {

    Modal.confirm({
      title: '拒絕',
      content: '確定要不批准此申請?',
      onOk: async () => {
        this.setState({
          isSpinLoading: true,
        })

        console.log('_onFinish', values)

        const res = await this._putSignupForm({
          ...values,
          status: 'NOT_APPROVED',
          id: this.props.id,
        })

        this.props.onOk()
      },
    });
  }

  _onLoadData = async (): Promise<any> => {
  };

  _putSignupForm = async (params: PutSignupFormParameters) => {
    const res = await api.PutSignupForm(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  render() {
    return (
      <Modal
        visible={this.props.show}
        title="拒絕申請"
        maskClosable={false}
        onOk={() => this.formRef && this.formRef.submit()}
        onCancel={() => this.props.onCancel()}
        okButtonProps={{ disabled: this.state.isSpinLoading }}
        width="80%"
      >
        <Spin spinning={this.state.isSpinLoading} style={{ width: '100%' }}>
          <JJ_From
            formRef={res => this.formRef = res}
            formListData={this._formListData()}
            onLoadData={this._onLoadData}
            onFinish={this._onFinish}
            formProps={{
              onValuesChange: (changedValues, values) => {
                const reasonParentCode = changedValues?.reasonParentCode || ''
                if (reasonParentCode) {
                  this.setState({ reasonParentCode })
                  this.formRef?.setFieldsValue({ reasonSubCode: undefined })
                }
                console.log('changedValues', changedValues)
                console.log('values', values)
              }
            }}
          />
        </Spin>
      </Modal>
    )
  }

}
export default SignupFormDetailsModalNotApprovedModal

