import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Tag, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import api, { GetFoodCardRecordListParameters, GetFoodCardRecordRes, GetFoodCardRes, GetKioskVendingRes, GetAreaRes, GetKioskVendingListParameters, GetProductRes, GetQuotaRes, GetQuotaListParameters } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import JJ_TableItemAction from '../../components/JJ_TableItemAction';
import JJ_TableItemMoment from '../../components/JJ_TableItemMoment';
import JJ_TableItemCopyableText from '../../components/JJ_TableItemCopyableText';
import FoodCardRecordEditAndAdd from './FoodCardRecordEditAndAdd';
import { connect, ConnectedProps } from 'react-redux';
import config from '../../config';
import JJ_TableItemCopyableId from '../../components/JJ_TableItemCopyableId';
import moment from 'moment';
import { ActionUserRes } from '../../actions/ActionUser'
import { getI18nText } from '../../public';
const { confirm } = Modal;

type EditAndAddModalType = { type: 'edit', id: string }

interface PageState {
  isSpinLoading: boolean
  kioskVendings: GetKioskVendingRes[]
  areas: GetAreaRes[]
  quotas: GetQuotaRes[]
  editAndAddModal: {
    show: boolean
    key: string
  } & EditAndAddModalType
}
interface Props {
  foodCardId?: string
}


interface Page {
  tableRef?: JJ_Table<GetFoodCardRecordRes> | null
}


type PageProps = Props & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes
}

const mapState = (state: RootState) => ({
  user: state.user
})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class FoodCardRecordList extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      kioskVendings: [],
      areas: [],
      isSpinLoading: true,
      quotas: [],
      editAndAddModal: {
        type: 'edit',
        id: '',
        show: false,
        key: new Date().toString()
      }
    }
  }
  tableRef?: JJ_Table<GetFoodCardRecordRes> | null
  componentDidMount() {
    this._initData()
  }

  _getKioskVendingList = async (params: GetKioskVendingListParameters) => {
    const res = await api.GetKioskVendingList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getAreaList = async (params: GetKioskVendingListParameters) => {
    const res = await api.GetAreaList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getQuotaList = async (params: GetQuotaListParameters) => {
    const res = await api.GetQuotaList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true
      })
      const areaList = await this._getAreaList({ count: 1000 })
      const kioskVendingList = await this._getKioskVendingList({ count: 1000 })
      const quotaList = await this._getQuotaList({ count: 1000 })

      this.setState({
        isSpinLoading: false,
        kioskVendings: kioskVendingList.data,
        areas: areaList.data,
        quotas: quotaList.data,
      })
    } catch (error) {
      this.setState({
        isSpinLoading: false
      })
    }
  }
  render() {
    return (
      <div id="FoodCardRecordList"
      >
        <Modal
          maskClosable={false}
          title={this.state.editAndAddModal.type === 'edit' ? '編輯食品卡取餐記錄' : '新建食品卡取餐記錄'}
          visible={this.state.editAndAddModal.show}
          footer={null}
          onCancel={() => this.setState(state => ({
            editAndAddModal: { ...state.editAndAddModal, show: false }
          }))}
          width={'85%'}
        >
          <FoodCardRecordEditAndAdd
            {...this.state.editAndAddModal}
            onFinish={async (props) => {
              this.setState(state => ({
                editAndAddModal: { ...state.editAndAddModal, show: false }
              }))
              switch (props.type) {
                case 'edit': {
                  this.tableRef && await this.tableRef.refreshData()
                  message.success(`編輯成功`)
                }
              }

            }}
          />
        </Modal>

        <Spin spinning={this.state.isSpinLoading}>
          {!this.state.isSpinLoading && (
            <JJ_Table<GetFoodCardRecordRes, {
              desc: 'ascend' | 'descend'
              status: 'NORMAL' | 'ERROR'
              isTestCard: 'true' | 'false'
              kioskVendingId?: string
              areaId?: string
              quotaId?: string
              rangePicker?: [moment.Moment, moment.Moment] | null
              type?: GetFoodCardRecordListParameters['type']
            }>
              tableProps={{ scroll: { x: 1600 } }}
              ref={ref => this.tableRef = ref}
              isSearchText={true}
              sourceItems={[
                {
                  type: 'select',
                  defaultValue: 'ascend',
                  key: 'desc',
                  span: 2,
                  options: [
                    {
                      value: 'ascend',
                      name: '升序',
                      disabled: false,
                    },
                    {
                      value: 'descend',
                      name: '降序',
                      disabled: false,
                    },
                  ]
                },
                {
                  type: 'select',
                  defaultValue: undefined,
                  key: 'isTestCard',
                  placeholder: '是否顯示測試卡',
                  allowClear: true,
                  span: 3,
                  options: [
                    {
                      value: 'true',
                      name: '顯示測試卡',
                      disabled: false,
                    },
                    {
                      value: 'false',
                      name: '隱藏測試卡',
                      disabled: false,
                    },
                  ]
                },
                {
                  type: 'select',
                  defaultValue: undefined,
                  key: 'status',
                  placeholder: '請選擇任意狀態',
                  allowClear: true,
                  span: 3,
                  options: [
                    {
                      value: 'NORMAL',
                      name: <Tag color={'blue'}>正常</Tag>,
                      disabled: false,
                    },
                    {
                      value: 'ERROR',
                      name: <Tag color={'error'}>出貨失敗</Tag>,
                      disabled: false,
                    },
                  ]
                },
                {
                  type: 'select',
                  defaultValue: undefined,
                  key: 'kioskVendingId',
                  placeholder: '請選擇任意取餐機',
                  allowClear: true,
                  showSearch: true,
                  filterOption: (input, option) => {
                    if (option && option.key) {
                      return (this.state.kioskVendings[Number(option.key)].name as string).toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        (this.state.kioskVendings[Number(option.key)].code as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    return false
                  },
                  options: this.state.kioskVendings.map(kioskVending => {
                    return {
                      value: kioskVending.id,
                      name: (
                        <Space align='center' >
                          <Tag color={'orange'}>{kioskVending.name}</Tag>
                          <Tag color={'blue'}>{kioskVending.code}</Tag>
                        </Space>
                      ),
                      disabled: false,
                    }
                  })
                },
                {
                  type: 'select',
                  defaultValue: undefined,
                  key: 'areaId',
                  placeholder: '請選擇任意區域',
                  allowClear: true,
                  showSearch: true,
                  filterOption: (input: string, option: any) => {
                    if (option && option.children.props.children.length) {
                      const text = option.children.props.children[0].props.children + '' + option.children.props.children[1].props.children;
                      return text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }
                    return false;
                  },
                  options: this.state.areas.map(area => {
                    return {
                      value: area.id,
                      name: (
                        <>
                          <span>{getI18nText(area.name, this.props.user.data.language)}</span>
                          <Tag style={{ marginLeft: 8 }}>{area.code}</Tag>
                        </>
                      ),
                      disabled: false,
                    }
                  })
                },

                {
                  type: 'select',
                  defaultValue: undefined,
                  key: 'quotaId',
                  placeholder: '請選擇任意配額',
                  allowClear: true,
                  showSearch: true,
                  filterOption: (input: string, option: any) => {
                    if (option && option.key) {
                      return getI18nText(this.state.quotas[Number(option.key)].name).toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    return false
                  },
                  options: this.state.quotas.map(quota => {
                    return {
                      value: quota.id,
                      name: (
                        <>
                          <span>{getI18nText(quota.name, this.props.user.data.language)}</span>
                        </>
                      ),
                      disabled: false,
                    }
                  })
                },

                {
                  type: 'select',
                  defaultValue: undefined,
                  key: 'type',
                  placeholder: '請選擇取餐方式',
                  allowClear: true,
                  options: [
                    {
                      value: <Tag color={'blue'}>二維碼</Tag>,
                      key: 'QR_CODE',
                    },
                    {
                      value: <Tag color={'green'}>八達通</Tag>,
                      key: 'OCTOPUS',
                    },
                  ].map(data => {
                    return {
                      value: data.key,
                      name: data.value,
                      disabled: false,
                    }
                  })
                },
                {
                  type: 'rangePicker',
                  key: 'rangePicker',
                  props: {
                  }
                },
              ]}
              columns={[
                {
                  key: 'action',
                  width: 80,
                  fixed: 'left',
                  render: (value: any, record) => {
                    return (
                      <JJ_TableItemAction
                        menus={[
                          {
                            type: 'item',
                            disabled: false,
                            key: 'edit',
                            name: '編輯',
                            onAction: () => {
                              this.setState(state => ({
                                editAndAddModal: { ...state.editAndAddModal, show: true, type: 'edit', id: record.id, key: new Date().toString() }
                              }))
                            }
                          },
                          {
                            type: 'item',
                            disabled: config.REACT_APP_DELETE_DISABLED,
                            key: 'delete',
                            name: '刪除',
                            onAction: () => {
                              confirm({
                                title: `是否删除${(record.foodCard as GetFoodCardRes).code}`,
                                icon: <ExclamationCircleOutlined />,
                                okText: 'Yes',
                                okType: 'danger',
                                cancelText: 'No',
                                onOk: async () => {
                                  try {
                                    await api.DeleteFoodCardRecord({ id: record.id })
                                    this.tableRef && this.tableRef.refreshData()
                                    message.success(`${(record.foodCard as GetFoodCardRes).code} 删除成功`)
                                  } catch (error) {
                                    message.error(`${(record.foodCard as GetFoodCardRes).code} 删除失败 [${error.message}]`);
                                  }
                                },
                                onCancel() {
                                },
                              });
                            }
                          },
                        ]}
                      />
                    )

                  },
                },
                {
                  title: 'ID',
                  dataIndex: 'id',
                  key: 'id',
                  width: 50,
                  fixed: 'left',
                  render: (value) => (
                    <JJ_TableItemCopyableId
                      text={value}
                    />
                  )
                },
                {
                  title: '食品卡號',
                  dataIndex: ['foodCard', 'code'],
                  key: 'foodCardCode',
                  width: 180,
                  fixed: 'left',
                },
                {
                  title: '產品名稱',
                  dataIndex: ['product', 'title'],
                  key: 'product.title',
                  width: 220,
                  render: (value, record) => {
                    const product = (record.product as GetProductRes)
                    return product?.title[this.props.user.data.language]
                  }

                },
                {
                  title: '訂單ID',
                  dataIndex: 'orderId',
                  key: 'orderId',
                  width: 150,
                  render: (value) => (
                    <JJ_TableItemCopyableText
                      text={value}
                    />
                  )
                },
                {
                  title: '機器ID',
                  dataIndex: ['kioskVending', 'kioskId'],
                  key: 'kioskVendingKioskId',
                  width: 150,
                  render: (value) => (
                    <JJ_TableItemCopyableText
                      text={value}
                    />
                  )
                },
                {
                  title: '機器名称',
                  dataIndex: ['kioskVending', 'name'],
                  key: 'kioskVendingKioskName',
                  width: 180,
                },
                {
                  title: '機器代碼',
                  width: 110,
                  dataIndex: ['kioskVending', 'code'],
                  key: 'kioskVendingKioskCode',
                  render: (value) => (<Tag color={'blue'}>{value}</Tag>)
                },
                {
                  title: '出貨失敗資訊',
                  dataIndex: ['errorText'],
                  key: 'errorText',
                  width: 200,
                  render: (value) => (
                    value ? <Tag color='error'>{value}</Tag> : `--`
                  )
                },
                {
                  title: '取餐方式',
                  dataIndex: ['type'],
                  key: 'type',
                  width: 100,
                  render: (value, record) => {

                    switch (record.type) {
                      case 'OCTOPUS': {
                        return (<Tag color={'green'}>八達通</Tag>)
                      }
                      case 'QR_CODE': {
                        return (<Tag color={'blue'}>二維碼</Tag>)
                      }
                    }

                  }
                },

                {
                  title: '創建時間',
                  dataIndex: 'createdAt',
                  key: 'createdAt',
                  width: 120,
                  fixed: 'right',
                  render: (value) => (
                    <JJ_TableItemMoment
                      date={value}
                    />
                  )
                },
              ]}
              title={'食品卡取餐記錄清單'}
              onDataSource={async (body) => {
                const { sourceItemBody } = body
                const params: {
                  isAscend?: GetFoodCardRecordListParameters['isAscend']
                  status?: GetFoodCardRecordListParameters['status']
                  scope?: GetFoodCardRecordListParameters['scope']
                  kioskVendingId?: GetFoodCardRecordListParameters['kioskVendingId']
                  areaId?: GetFoodCardRecordListParameters['areaId']
                  beginAt?: GetFoodCardRecordListParameters['beginAt']
                  endAt?: GetFoodCardRecordListParameters['endAt']
                  type?: GetFoodCardRecordListParameters['type']
                  foodCardId?: GetFoodCardRecordListParameters['foodCardId']
                  isTestCard?: GetFoodCardRecordListParameters['isTestCard']
                  quotaId?: GetFoodCardRecordListParameters['quotaId']
                } = {}
                if (sourceItemBody) {
                  if (sourceItemBody.desc) {
                    params.isAscend = sourceItemBody.desc === 'ascend' ? true : false
                  }

                  if (sourceItemBody.isTestCard === 'true') {
                    params.isTestCard = true
                  }

                  if (sourceItemBody.isTestCard === 'false') {
                    params.isTestCard = false
                  }

                  params.status = sourceItemBody.status
                  params.kioskVendingId = sourceItemBody.kioskVendingId
                  params.areaId = sourceItemBody.areaId
                  params.quotaId = sourceItemBody.quotaId
                  if (this.props.foodCardId) {
                    params.foodCardId = this.props.foodCardId
                  }

                  const rangePicker = sourceItemBody.rangePicker
                  if (rangePicker && rangePicker.length === 2) {
                    const [beginAtMoment, endAtMoment,] = rangePicker
                    params.beginAt = moment(beginAtMoment).startOf('day').toISOString()
                    params.endAt = moment(endAtMoment).endOf('day').toISOString()
                  }
                  params.type = sourceItemBody.type
                }
                params.scope = ['foodCard', 'kioskVending', 'area', 'product']
                const res = await api.GetFoodCardRecordList({
                  ...body,
                  ...params,
                })
                if (res.kind !== 'ok') throw new Error(res.kind)
                return res.data
              }}
            />
          )}
        </Spin>

      </div>
    )
  }

}
export default connector(FoodCardRecordList)

