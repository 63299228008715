import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetSignupFormFamilyMemberRes, GetSignupFormFamilyMemberParameters, PutSignupFormFamilyMemberParameters, PutSignupFormParameters } from '../../api';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button, Typography, Input, Select, Tag, DatePicker } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';
import { ifError } from 'assert';

const { Paragraph } = Typography;
const { TextArea } = Input;
const { Option } = Select;

interface PageState {
  isSpinLoading: boolean
  discretionReason?:
  'Pregnant' |
  'Family with Child under 5' |
  'Disabled' |
  'Other'
  detailDiscretionReason?: string
}

interface Props {
  show: boolean
  onOk: (data: {
    discretionReason?:
    'Pregnant' |
    'Family with Child under 5' |
    'Disabled' |
    'Other'
    detailDiscretionReason?: string
  }) => void
  onCancel: () => void
  id: string
}

interface Page {
}

type PageProps = Props

class SignupFormDetailsModalDiscretionReason extends React.Component<PageProps, PageState> implements Page {

  formRef: (
    FormInstance<{
    }> | null
  ) | undefined

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: false,
    }
  }
  componentDidMount() {
  }



  _formListData = (): FormItemData[] => {

    return [
      {
        id: 'discretionReason',
        label: '續期原因',
        rules: [
          {
            required: true,
            message: `請選擇 續期原因`
          }
        ],
        componet: (
          <Select>
            {[
              {
                value: 'Pregnant',
                label: '孕婦',
              },
              {
                value: 'Family with Child under 5',
                label: '有5歲以下兒童的家庭',
              },
              {
                value: 'Disabled',
                label: '殘疾人仕',
              },
              {
                value: 'Other',
                label: '其他',
              },
            ].map(option => (
              <Option key={option.value} value={option.value}>{option.label}</Option>
            ))}
          </Select>
        ),
      },
      ...(this.state.discretionReason === 'Other' ? [
        {
          id: 'detailDiscretionReason',
          label: '其他原因',
          rules: [
            {
              required: true,
              message: `請輸入 其他原因`
            }
          ],
        },

      ] : []),
    ]
  }

  _onFinish = async (values: any): Promise<void> => {

    Modal.confirm({
      title: '續期原因',
      content: '確定要批准此續期?',
      onOk: async () => {
        this.setState({
          isSpinLoading: true,
        })
        console.log('_onFinish', values)
        this.props.onOk({...values})
      },
    });
  }

  _onLoadData = async (): Promise<any> => {
  };

  _putSignupForm = async (params: PutSignupFormParameters) => {
    const res = await api.PutSignupForm(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  render() {
    return (
      <Modal
        visible={this.props.show}
        title="續期原因"
        maskClosable={false}
        onOk={() => this.formRef && this.formRef.submit()}
        onCancel={() => this.props.onCancel()}
        okButtonProps={{ disabled: this.state.isSpinLoading }}
        width="80%"
      >
        <Spin spinning={this.state.isSpinLoading} style={{ width: '100%' }}>
          <JJ_From
            formRef={res => this.formRef = res}
            formListData={this._formListData()}
            onLoadData={this._onLoadData}
            onFinish={this._onFinish}
            formProps={{
              onValuesChange: (changedValues, values) => {
                const discretionReason = values?.discretionReason || ''
                if (discretionReason) {
                  this.setState({ discretionReason })
                }
                if (discretionReason !== 'Other') {
                  this.formRef?.setFieldsValue({ detailDiscretionReason: undefined })
                }
                console.log('changedValues', changedValues)
                console.log('values', values)
              }
            }}
          />
        </Spin>
      </Modal>
    )
  }

}
export default SignupFormDetailsModalDiscretionReason

